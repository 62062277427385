import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

export const StyledImageContainer = styled.div`
  width: auto;
  text-align: ${props => props.align};
`

export const GatsbyStyledImage = styled(GatsbyImage)`
  picture {
    img {
      object-fit: contain !important;
      max-width: ${props => props.maxwidth || ''};
      min-width: ${props => props.minwidth || ''};
      width: ${props => props.imagewidth || 'auto'};
    }
  }
`
