import { bool, node, oneOf } from 'prop-types'
import React from 'react'
import { StyledHeading } from './heading.styles'

const Heading = ({
  children,
  desktopSize,
  weight,
  underline,
  type,
  variant,
  align
}) => (
  <StyledHeading
    as={type}
    desktopSize={desktopSize}
    underline={underline}
    weight={weight}
    variant={variant}
    align={align}
  >
    {children}
  </StyledHeading>
)

Heading.propTypes = {
  /**
   * Heading contents
   */
  children: node.isRequired,
  /**
   * How large should the heading be in desktop?
   */
  desktopSize: oneOf(['lg', 'xl', '2xl', '4xl']),
  /**
   * How font weight should the heading have?
   */
  weight: oneOf(['extrabold', 'black']),
  underline: bool,
  /**
   * Heading is h1, h2, h3 or h4
   */
  type: oneOf(['h1', 'h2', 'h3', 'h4']),
  /**
   * Heading is primary, light or dark
   */
  variant: oneOf(['primary', 'light', 'dark']),
  /**
   *  Heading text alignment
   */
  align: oneOf(['left', 'center', 'right'])
}

Heading.defaultProps = {
  children: 'Heading',
  desktopSize: '4xl',
  underline: false,
  weight: 'extrabold',
  variant: 'dark',
  align: 'left'
}

export default Heading
