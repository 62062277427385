import styled from 'styled-components'

export const StyledBox = styled.a`
  text-decoration: none;
  width: 100%;
  display: flex;
  gap: 12px;

  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    padding: 8px;
    background: linear-gradient(0deg, #a0cfff 0%, #99cfd5 63.5%, #96d0c3 100%);
  }

  @media (min-width: 768px) {
    img {
      width: 100px;
      height: 100px;
    }
  }
`

export const StyledBoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
