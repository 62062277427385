import styled from 'styled-components'
import { getFluidSize } from '../../../utils/getFluidSize'
import { StyledBox } from '../../02_molecules/box/box.styles'
import { StyledServices } from '../../03_organisms/services/services.styles'
import { StyledTeam } from '../../03_organisms/team/team.styles'
import { StyledTechnologies } from '../../03_organisms/technologies/technologies.styles'
import { ThemeDefault } from '../../00_tokens/theme-default'

const heading = {
  color: {
    primary: 'orangeYellow',
    light: 'white',
    dark: 'black'
  },
  fontSize: {
    lg: { mobile: 'sm' },
    xl: { mobile: 'lg' },
    '2xl': { mobile: 'xl' },
    '4xl': { mobile: '3xl' }
  }
}

export const StyledHeading = styled.h1`
  font-size: ${props =>
    getFluidSize(
      ThemeDefault,
      'fontSize',
      props.desktopSize,
      heading.fontSize[props.desktopSize].mobile
    )};
  font-weight: ${props => ThemeDefault.fontWeight[props.weight]};
  color: ${props => ThemeDefault.color[heading.color[props.variant]]};
  text-transform: uppercase;
  text-align: ${props => props.align};
  ${props =>
    props.underline &&
    'text-decoration: underline; text-decoration-thickness: 2px;'}

  ${StyledServices} &, ${StyledTechnologies} &, ${StyledTeam} & {
    text-align: center;
  }

  @media (min-width: 768px) {
    ${StyledTeam} & {
      align-self: flex-start;
    }
  }

  ${StyledBox} & {
    text-align: left;
  }
`
