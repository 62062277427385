import { arrayOf, bool, func, shape, string } from 'prop-types'
import React, { Fragment, useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import useOnClickOutside from '../../../utils/customHooks'
import Heading from '../../01_atoms/heading'
import Icon from '../../01_atoms/icon/Icon'
import Image from '../../01_atoms/image'
import Paragraph from '../../01_atoms/paragraph'
import Spacer from '../../01_atoms/spacer'
import {
  StyledContactItem,
  StyledContactMobile,
  StyledContactMobileScrim,
  StyledContactMobileSheet,
  StyledItem,
  StyledItemInfo
} from './contact-mobile.styles'

const ContactMobile = ({ contactOpen, setContactOpen, contactLinks }) => {
  const [documentHeight, setDocumentHeight] = useState(0)
  const ref = useRef()
  useOnClickOutside(contactOpen, ref, () => setContactOpen(!contactOpen))
  useEffect(() => {
    setDocumentHeight(innerHeight)
  }, [contactOpen])

  return (
    <StyledContactMobile
      documentHeight={`${documentHeight}px`}
      contactOpen={contactOpen}
    >
      <StyledContactMobileScrim contactOpen={contactOpen} />
      <StyledContactMobileSheet contactOpen={contactOpen} ref={ref}>
        {contactLinks.map(contactLink => (
          <Fragment key={contactLink.id}>
            <StyledContactItem
              href={contactLink.url}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => setContactOpen(!contactOpen)}
            >
              <StyledItem>
                <Image
                  alt={contactLink.label}
                  src={contactLink.icon.file.url}
                />
                <Spacer size="4" />
                <StyledItemInfo>
                  <Heading desktopSize="lg" type="h4" variant="light">
                    {contactLink.label}
                  </Heading>
                  <Paragraph variant="primary">{contactLink.text}</Paragraph>
                </StyledItemInfo>
              </StyledItem>
              <Icon type="chevronRight" size="sm" />
            </StyledContactItem>
            <hr />
          </Fragment>
        ))}
      </StyledContactMobileSheet>
    </StyledContactMobile>
  )
}

ContactMobile.propTypes = {
  contactOpen: bool,
  setContactOpen: func,
  contactLinks: arrayOf(
    shape({
      id: string,
      text: string,
      label: string,
      url: string,
      new_window: bool,
      variant: string,
      icon: shape({
        file: shape({
          url: string
        })
      })
    })
  )
}

ContactMobile.defaultProps = {
  contactOpen: false,
  contactLinks: []
}

export default ContactMobile
