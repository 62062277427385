import { bool, func, string } from 'prop-types'
import React from 'react'
import Button from '../../01_atoms/button'
import Icon from '../../01_atoms/icon/Icon'

const ButtonIcon = ({ id, icon, onClick, disabled }) => (
  <Button id={id} onClick={onClick} disabled={disabled}>
    <Icon type={icon} />
  </Button>
)

ButtonIcon.propTypes = {
  id: string,
  icon: string.isRequired,
  onClick: func,
  disabled: bool
}

ButtonIcon.defaultProps = {
  icon: 'paperPlane',
  disabled: false
}

export default ButtonIcon
