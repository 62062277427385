import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { PureHeader } from './PureHeader'

const Header = () => {
  const data = useStaticQuery(graphql`
    query get_section_header {
      allContentfulSectionHeader {
        edges {
          node {
            title
            logo {
              gatsbyImageData(width: 150)
            }
            navLinks {
              id
              label
              url
              variant
            }
          }
        }
      }
      allContentfulSectionContact {
        edges {
          node {
            title
            contactLinks {
              id
              label
              new_window
              url
              variant
              text
              icon {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  // console.log('Header -> data :::::', data)

  const {
    title,
    logo,
    navLinks,
    buttons
  } = data.allContentfulSectionHeader.edges[0].node

  const { contactLinks } = data.allContentfulSectionContact.edges[0].node

  return (
    <PureHeader
      logo={logo}
      title={title}
      navLinks={navLinks}
      buttons={buttons}
      contactLinks={contactLinks}
    />
  )
}

export default Header
