import styled from 'styled-components'
import { ThemeDefault } from '../../00_tokens/theme-default'

export const StyledContactMobile = styled.section`
  pointer-events: ${props => (props.contactOpen ? 'unset' : 'none')};
  visibility: ${props => (props.contactOpen ? 'visible' : 'hidden')};
  width: 100vw;
  height: ${props => props.documentHeight};
  overflow: hidden;
  z-index: ${props => ThemeDefault.zIndex[40]};
  position: fixed;
  top: 0;
  transition: visibility 0.3s ease-in-out;

  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledContactMobileScrim = styled.div`
  opacity: ${props => (props.contactOpen ? 1 : 0)};
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-in-out;
`

export const StyledContactMobileSheet = styled.div`
  transform: ${props =>
    props.contactOpen ? 'translateY(0)' : 'translateY(100%)'};
  padding: 1.5rem;
  background-color: ${() => ThemeDefault.color.orangeYellow};
  border: 1px solid white;
  border-bottom: none;
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: 0px -8px 12px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);

  hr {
    border: 0;
    background: ${() => ThemeDefault.color.black};
    width: 100%;
    height: 1px;
  }

  hr:last-child {
    display: none;
  }

  *|*:link {
    color: transparent;
  }
`

export const StyledContactItem = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const StyledItem = styled.div`
  display: flex;

  img {
    filter: brightness(30%);
  }
`

export const StyledItemInfo = styled.div`
  min-width: max-content;
  display: flex;
  flex-direction: column;
`
