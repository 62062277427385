import styled from 'styled-components'
import { StyledBox } from '../../02_molecules/box/box.styles'

export const StyledTeam = styled.section`
  ${props =>
    props.theming.background && `background: ${props.theming.background};`}
  padding-top: calc(90px + (136 - 90) * ((100vw - 320px) / (768 - 320)));
  display: flex;
  justify-content: center;

  @media (min-width: 992px) {
    padding-top: calc(136px + (192 - 136) * ((100vw - 768px) / (1200 - 768)));
  }
`

export const StyledTeamBoxes = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 8px;

  > ${StyledBox}:nth-child(3n + 1) {
    img {
      --color-bg-1: #a0cfff;
      --color-bg-2: #99cfd5;
      --color-bg-3: #96d0c3;
      background: linear-gradient(
        0deg,
        var(--color-bg-1) 0%,
        var(--color-bg-2) 63.5%,
        var(--color-bg-3) 100%
      );
    }

    &:hover {
      img {
        --color-box-1: #a0cfff;
        --color-box-2: #99cfd5;
        --color-box-3: #96d0c3;
        box-shadow: 0 0 6px var(--color-box-1), 0 0 8px var(--color-box-2),
          0 0 12px var(--color-box-3), 0 0 16px var(--color-box-3);
      }
    }
  }

  > ${StyledBox}:nth-child(3n + 2) {
    img {
      --color-bg-1: #a26dba;
      --color-bg-2: #bc7fa2;
      --color-bg-3: #eca176;
      --color-bg-4: #ffaf65;
      background: linear-gradient(
        0deg,
        var(--color-bg-1) 0%,
        var(--color-bg-2) 24.7%,
        var(--color-bg-3) 75%,
        var(--color-bg-4) 100%
      );
    }

    &:hover {
      img {
        --color-box-1: #feae65;
        --color-box-2: #f0a471;
        --color-box-3: #c98895;
        --color-box-4: #a36db9;
        box-shadow: 0 0 6px var(--color-box-1), 0 0 8px var(--color-box-2),
          0 0 12px var(--color-box-3), 0 0 16px var(--color-box-4);
      }
    }
  }

  > ${StyledBox}:nth-child(3n + 3) {
    img {
      --color-bg-1: #a1b0ff;
      --color-bg-2: #a4b0fd;
      --color-bg-3: #ffbed1;
      background: linear-gradient(
        0deg,
        var(--color-bg-1) 0%,
        var(--color-bg-2) 3.7%,
        var(--color-bg-3) 100%
      );
    }

    &:hover {
      img {
        --color-box-1: #a1b0ff;
        --color-box-2: #a4b0fd;
        --color-box-3: #ffbed1;
        box-shadow: 0 0 6px var(--color-box-1), 0 0 8px var(--color-box-2),
          0 0 12px var(--color-box-3), 0 0 16px var(--color-box-3);
      }
    }
  }

  @media (min-width: 575px) {
    > * {
      max-width: 50%;
      flex: 1 0 45%;
      justify-content: flex-start;
    }
  }
`
