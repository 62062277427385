import styled from 'styled-components'
import { ThemeDefault } from '../../00_tokens/theme-default'

export const StyledMenuMobile = styled.section`
  padding: 24px;
  background-color: ${() => ThemeDefault.color.orangeYellow};
  border: 1px solid white;
  border-top: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  top: ${props => (props.navOpen ? '64px' : '-50vh')};
  left: 0;
  z-index: ${() => ThemeDefault.zIndex[40]};
  transition: top 1s ease-in-out;

  hr {
    border: 0;
    background: ${() => ThemeDefault.color.black};
    width: 100%;
    height: 1px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`
