const ASSETS_PATH = '/icons'

const iconMap = {
  paperPlane: 'paper-plane',
  chevronRight: 'chevron-right'
}

const iconSize = {
  sm: 16,
  md: 24,
  lg: 32,
  xl: 40
}

export const mapType = type => `${ASSETS_PATH}/${iconMap[type]}.svg`
export const mapSize = size => iconSize[size]
