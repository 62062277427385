import styled from 'styled-components'
import { ThemeDefault } from '../../00_tokens/theme-default'

export const StyledBurgerMenu = styled.div`
  display: block;
  position: relative;
  z-index: 4;

  input {
    margin: 0;
    width: 32px;
    height: 24px;
    cursor: pointer;
    opacity: 0;
    z-index: 3;
    display: block;
    position: absolute;
  }

  span {
    background-color: ${() => ThemeDefault.color.raisinBlack};
    width: 32px;
    height: 4px;
    margin-bottom: 4px;
    border-radius: 3px;
    z-index: 2;
    display: block;
    position: relative;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }

  input:checked ~ span {
    width: 32px;
    opacity: 1;
    transform: rotate(45deg) translate(0px, -4px);
    background-color: ${() => ThemeDefault.color.orangeYellow};
  }

  input:checked ~ span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ span:nth-last-child(1) {
    transform: rotate(-45deg) translate(2px, -1px);
  }

  @media (min-width: 768px) {
    display: none;
  }
`
