import styled from 'styled-components'

export const StyledClients = styled.section`
  ${props =>
    props.theming.background && `background: ${props.theming.background};`}
  margin-top: calc(24px + (56 - 24) * ((100vw - 320px) / (768 - 320)));
  padding-top: calc(16px + (32 - 16) * ((100vw - 320px) / (768 - 320)));
  display: flex;
  justify-content: center;

  .svg--bottom {
    top: initial;
    bottom: calc(-55px + (-100 - (-55)) * ((100vw - 320px) / (575 - 320)));
  }

  @media (min-width: 575px) {
    .svg--bottom {
      bottom: calc(-100px + (-130 - (-100)) * ((100vw - 575px) / (768 - 575)));
    }
  }

  @media (min-width: 768px) {
    margin-top: calc(56px + (88 - 56) * ((100vw - 768px) / (1200 - 768)));
    padding-top: calc(32px + (48 - 32) * ((100vw - 768px) / (1200 - 768)));

    .svg--bottom {
      bottom: calc(-130px + (-165 - (-130)) * ((100vw - 768px) / (992 - 768)));
    }
  }

  @media (min-width: 992px) {
    .svg--bottom {
      bottom: calc(-165px + (-205 - (-165)) * ((100vw - 992px) / (1200 - 992)));
    }
  }
`

export const StyledLogoClientSlider = styled.div`
  @media (min-width: 992px) {
    max-width: 70%;
  }
`
