import React from 'react'
import { node, oneOf } from 'prop-types'
import { StyledParagraph } from './paragraph.styles'

const Paragraph = ({ children, variant }) => (
  <StyledParagraph variant={variant}>{children}</StyledParagraph>
)

Paragraph.propTypes = {
  /**
   * Paragraph contents
   */
  children: node.isRequired,
  /**
   * Paragraph is 'default', 'primary', 'secondary', 'tertiary'
   */
  variant: oneOf(['default', 'primary', 'secondary', 'tertiary'])
}

Paragraph.defaultProps = {
  children: 'Paragraph',
  variant: 'default'
}

export default Paragraph
