import { func } from 'prop-types'
import { arrayOf, bool, shape, string } from 'prop-types'
import React, { Fragment } from 'react'
import Button from '../../01_atoms/button'
import { StyledMenuMobile } from './menu-mobile.styles'

const MenuMobile = ({ navOpen, setNavOpen, navLinks }) => {
  
  return (
    <StyledMenuMobile navOpen={navOpen}>
      {navLinks.map(navLink => (
        <Fragment key={navLink.id}>
          {
            typeof window !== 'undefined' &&
              <Button
                to={location.pathname !== '/' ? '/' : navLink.url}
                type="text"
                variant="secondary"
                onClick={() => setNavOpen(!navOpen)}
              >
                {navLink.label}
              </Button>
          }
          <hr />
        </Fragment>
      ))}
    </StyledMenuMobile>
  )
}

MenuMobile.propTypes = {
  navOpen: bool,
  setNavOpen: func,
  navLinks: arrayOf(
    shape({
      id: string,
      label: string,
      url: string,
      variant: string
    })
  )
}

MenuMobile.defaultProps = {
  navOpen: false,
  navLinks: []
}

export default MenuMobile
