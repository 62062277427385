import React from 'react'
import { bool, func } from 'prop-types'
import { StyledBurgerMenu } from './burger-menu.styles'

const BurguerMenu = ({ navOpen, setNavOpen }) => (
  <StyledBurgerMenu
    onClick={() => setNavOpen(!navOpen)}
    role="button"
    tabIndex={0}
  >
    <input type="checkbox" checked={navOpen} readOnly />
    <span />
    <span />
    <span />
  </StyledBurgerMenu>
)

BurguerMenu.propTypes = {
  /**
   * Flag to show or hide menu actions
   */
  navOpen: bool,
  /**
   * Function to set the flag to show or hide menu actions
   */
  setNavOpen: func
}

export default BurguerMenu
