export const ThemeDefault = {
  color: {
    transparent: 'transparent',
    orangeYellow: '#eeb500',
    white: '#ffffff',
    davysGrey: '#5d5d5d',
    black: '#000000',
    raisinBlack: '#262424',
    lavenderWeb: '#f0ebff'
  },
  spacing: {
    px: '1px',
    0: '0',
    1: '0.25rem', //4
    2: '0.5rem', //8
    3: '0.75rem', //12
    4: '1rem', //16
    5: '1.25rem', //20
    6: '1.5rem', //24
    8: '2rem', //32
    10: '2.5rem', //40
    12: '3rem', //48
    16: '4rem', //64
    20: '5rem', //80
    24: '6rem', //96
    32: '8rem', //128
    40: '10rem', //160
    48: '12rem', //192
    56: '14rem', //224
    64: '16rem' //256
  },
  zIndex: {
    auto: 'auto',
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50'
  },
  fontSize: {
    base: {
      mobile: '16px',
      desktop: '18px'
    },
    xs: '.75rem', //12
    sm: '.875rem', //14
    md: '1rem', //16
    lg: '1.125rem', //18
    xl: '1.25rem', //20
    '2xl': '1.5rem', //24
    '3xl': '1.875rem', //30
    '4xl': '2.25rem', //35
    '5xl': '3rem', //48
    '6xl': '4rem' //64
  },
  fontWeight: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  lineHeight: {
    none: 1,
    tight: 1.25,
    snug: 1.375,
    normal: 1.5,
    relaxed: 1.625,
    loose: 2
  }
}
