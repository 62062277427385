import styled from 'styled-components'
import { ThemeDefault } from '../../00_tokens/theme-default'

export const StyledHeader = styled.header`
  background-color: ${() => ThemeDefault.color.lavenderWeb};
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: ${() => ThemeDefault.zIndex[50]};

  @media (min-width: 768px) {
    padding: 1rem 0;
  }

  @media (min-width: 1200px) {
    padding: 1.5rem 2rem;
  }
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  #paper-plane {
    @media (min-width: 768px) {
      display: none;
    }
  }

  @media (min-width: 768px) {
    gap: 24px;
  }

  @media (min-width: 992px) {
    gap: 32px;
  }

  @media (min-width: 1200px) {
    gap: 10%;
  }
`
