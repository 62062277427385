import { arrayOf, bool, shape, string } from 'prop-types'
import React from 'react'
import Button from '../../01_atoms/button'
import Image from '../../01_atoms/image'
import Paragraph from '../../01_atoms/paragraph'
import Spacer from '../../01_atoms/spacer'
import {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterLinks
} from './footer.styles'

export const PureFooter = ({ logo, title, email, socialLinks, copyright }) => {
  //TODO: new window link
  copyright = JSON.parse(copyright.raw)
  const currentYear = new Date().getFullYear()
  const copyrightMessage = `(C) 2019 - ${currentYear}, ${copyright.content[0].content[0].value}`
  return (
    <>
      <StyledFooter>
        <StyledFooterContainer className="container">
          <Image
            id="footer__logo"
            to="/"
            imageData={logo.gatsbyImageData}
            alt={title}
          />

          <Button href={email.url} type="text" variant="default">
            {email.text}
          </Button>

          {socialLinks.length > 0 && (
            <StyledFooterLinks>
              {socialLinks.map(socialLink => (
                <Image
                  key={socialLink.id}
                  src={socialLink.icon.file.url}
                  href={socialLink.url}
                  alt={socialLink.label}
                  variant={socialLink.variant}
                />
              ))}
            </StyledFooterLinks>
          )}
        </StyledFooterContainer>

        <Spacer type="horizontal" size="8" />

        <Paragraph variant="secondary">{copyrightMessage}</Paragraph>
      </StyledFooter>
    </>
  )
}

PureFooter.propTypes = {
  logo: shape({
    gatsbyImageData: shape({
      src: string
    })
  }).isRequired,
  title: string,
  email: shape({
    label: string,
    url: string,
    new_window: bool,
    variant: string,
    text: string
  }),
  socialLinks: arrayOf(
    shape({
      id: string,
      label: string,
      url: string,
      new_window: bool,
      variant: string,
      icon: shape({
        file: shape({
          url: string
        })
      })
    })
  ),
  copyright: shape({
    raw: string
  })
}

PureFooter.defaultProps = {
  logo: {},
  title: 'Footer',
  email: '',
  socialLinks: [],
  copyright: {}
}
