import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'
import { StyledMenuMobile } from '../../02_molecules/menu-mobile/menu-mobile.styles'
import { Link } from 'gatsby'
import { ThemeDefault } from '../../00_tokens/theme-default'


const textLink = {
  color: {
    default: 'white',
    primary: 'raisinBlack',
    secondary: 'white',
    tertiary: 'white'
  },
  textTransform: {
    default: 'none',
    primary: 'uppercase',
    secondary: 'uppercase',
    tertiary: 'capitalize'
  },
  hover: {
    textDecoration: {
      default: 'underline',
      primary: 'none',
      secondary: 'none',
      tertiary: 'underline'
    }
  }
}

const buttonLink = {
  color: {
    primary: 'white',
    secondary: 'black',
    tertiary: 'white'
  },
  backgroundColor: {
    primary: 'orangeYellow',
    secondary: 'transparent',
    tertiary: 'orangeYellow'
  },
  borderColor: {
    primary: 'transparent',
    secondary: 'black',
    tertiary: 'transparent'
  }
}

// Global Styles for AnchorLink
const StyledLink = styled(AnchorLink)`
  font-family: Montserrat, sans-serif;
  font-size: ${props =>
    props.variant === 'default'
      ? ThemeDefault.fontSize.lg
      : ThemeDefault.fontSize.sm};
  font-weight: ${props =>
    props.variant === 'default'
      ? ThemeDefault.fontWeight.light
      : ThemeDefault.fontWeight.black};
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
`

export const StyledTextLink = styled(StyledLink)`
  color: ${props => ThemeDefault.color[textLink.color[props.variant]]};
  text-transform: ${props => textLink.textTransform[props.variant]};
  background-color: transparent;
  border: none;

  &:hover {
    color: ${props => ThemeDefault.color.orangeYellow};
    text-decoration: ${props => textLink.hover.textDecoration[props.variant]};
  }

  ${StyledMenuMobile} & {
    &:hover {
      color: ${props => ThemeDefault.color.raisinBlack};
    }
  }

  @media (min-width: 992px) {
    font-size: ${props => ThemeDefault.fontSize.md};
  }
`

export const StyledButtonLink = styled(StyledLink)`
  color: ${props => ThemeDefault.color[buttonLink.color[props.variant]]};
  width: min-content;
  padding: 0.5rem 0.75rem;
  background-color: ${props =>
    ThemeDefault.color[buttonLink.backgroundColor[props.variant]]};
  border: 1px solid
    ${props => ThemeDefault.color[buttonLink.borderColor[props.variant]]};
  border-radius: 12px;

  &:hover {
    box-shadow: ${props =>
      props.variant === 'primary' && '0px 0px 4px 4px rgba(238, 181, 0, 0.2)'};
  }

  @media (min-width: 992px) {
    font-size: ${props => ThemeDefault.fontSize.md};
    padding: 1rem 2rem;
  }

  @media (min-width: 1200px) {
    width: 230px;
    padding: 1rem 0;
  }
`

export const StyledGatsbyLink = styled(Link)`
  color: ${props => ThemeDefault.color[buttonLink.color[props.variant]]};
  width: min-content;
  padding: 0.5rem 0.75rem;
  background-color: ${props =>
    ThemeDefault.color[buttonLink.backgroundColor[props.variant]]};
  border: 1px solid
    ${props => ThemeDefault.color[buttonLink.borderColor[props.variant]]};
  border-radius: 12px;

  &:hover {
    box-shadow: ${props =>
      props.variant === 'primary' && '0px 0px 4px 4px rgba(238, 181, 0, 0.2)'};
  }

  @media (min-width: 992px) {
    font-size: ${props => ThemeDefault.fontSize.md};
    padding: 1rem 2rem;
  }

  @media (min-width: 1200px) {
    width: 230px;
    padding: 1rem 0;
  }
`

// Styles for Button
export const StyledButton = styled.button`
  pointer-events: ${props => (!props.disabled ? 'unset' : 'none')};
  outline: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 1px solid transparent;
`
