import styled from 'styled-components'
import { getFluidSize } from '../../../utils/getFluidSize'
import { ThemeDefault } from '../../00_tokens/theme-default'

const paragraph = {
  fontSize: {
    default: { mobile: 'sm', desktop: 'md' },
    primary: { mobile: 'sm', desktop: 'lg' },
    secondary: { mobile: 'sm', desktop: 'md' },
    tertiary: { mobile: 'sm', desktop: 'xs' }
  },
  fontWeight: {
    default: 'light',
    primary: 'normal',
    secondary: 'light',
    tertiary: 'thin'
  },
  color: {
    default: 'white',
    primary: 'davysGrey',
    secondary: 'orangeYellow',
    tertiary: 'raisinBlack'
  },
  lineHeight: {
    default: 'tight',
    primary: 'snug',
    secondary: 'tight',
    tertiary: 'none'
  }
}

export const StyledParagraph = styled.p`
  font-size: ${props =>
    getFluidSize(
      ThemeDefault,
      'fontSize',
      paragraph.fontSize[props.variant].desktop,
      paragraph.fontSize[props.variant].mobile
    )};
  font-weight: ${props =>
    ThemeDefault.fontWeight[paragraph.fontWeight[props.variant]]};
  color: ${props => ThemeDefault.color[paragraph.color[props.variant]]};
  line-height: ${props =>
    ThemeDefault.lineHeight[paragraph.lineHeight[props.variant]]};
`
