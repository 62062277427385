import { arrayOf, shape, string } from 'prop-types'
import React from 'react'
import Button from '../../01_atoms/button'
import { StyledMenuDesktop } from './menu-desktop.styles'

const MenuDesktop = ({ navLinks, buttons }) => {
  return (
    <StyledMenuDesktop>
      {typeof window !== 'undefined' && navLinks.map(navLink => (
        <Button
          key={navLink.id}
          to={location.pathname !== '/' ? '/' : navLink.url}
          type="text"
          variant={navLink.variant}
        >
          {navLink.label}
        </Button>
      ))}
      {typeof window !== 'undefined' && buttons.map(button => (
        <Button
          key={button.id}
          to={location.pathname !== '/' ? '/' : navLink.url}
          type="button"
          variant={button.variant}
        >
          {button.label}
        </Button>
      ))}
    </StyledMenuDesktop>
  )
}

MenuDesktop.propTypes = {
  navLinks: arrayOf(
    shape({
      id: string,
      label: string,
      url: string,
      variant: string
    })
  ),
  buttons: arrayOf(
    shape({
      id: string,
      label: string,
      url: string,
      variant: string
    })
  )
}

MenuDesktop.defaultProps = {
  navLinks: [],
  buttons: []
}

export default MenuDesktop
