import { Link } from 'gatsby'
import { bool, func, node, oneOf, string } from 'prop-types'
import React from 'react'
import { StyledButton, StyledButtonLink, StyledTextLink, StyledGatsbyLink } from './button.styles'

const Button = ({
  id,
  children,
  to,
  href,
  onClick,
  variant,
  type,
  disabled
}) => {
  if (href) {
    switch (type) {
      case 'text':
        return (
          <StyledTextLink as="a" href={href} variant={variant}>
            {children}
          </StyledTextLink>
        )

      case 'button':
        return (
          <StyledButtonLink as="a" href={href} variant={variant}>
            {children}
          </StyledButtonLink>
        )

      default:
        break
    }
  } else if (to) {
    switch (type) {
      case 'text':
        return (
          <StyledTextLink
            as={/^#/.test(to) ? '' : Link}
            to={/^#/.test(to) ? '' : to}
            href={/^#/.test(to) ? to : ''}
            offset="100"
            variant={variant}
            onClick={onClick}
          >
            {children}
          </StyledTextLink>
        )

      case 'button':
        return (
          <StyledButtonLink href={to} variant={variant} onClick={onClick}>
            {children}
          </StyledButtonLink>
        )
      case 'buttonLink':
         return (<StyledGatsbyLink id={id} to={to} variant={variant}>
            {children}
        </StyledGatsbyLink>)
      default:
        break
    }
  } else if (onClick) {
    return (
      <StyledButton
        id={id}
        onClick={onClick}
        variant={variant}
        disabled={disabled}
      >
        {children}
      </StyledButton>
    )
  }
}

Button.propTypes = {
  /**
   * Button contents
   */
  children: node.isRequired,
  /**
   * Button id
   */
  id: string,
  /**
   * Button Link path
   */
  to: string,
  /**
   * Button external URL
   */
  href: string,
  /**
   * Button behaviour on click event
   */
  onClick: func,
  /**
   * Button type
   */
  type: oneOf(['text', 'button', 'buttonLink', 'tab']),
  /**
   * Button is primary, secondary or tertiary
   */
  variant: oneOf(['default', 'primary', 'secondary', 'tertiary']),
  disabled: bool
}

Button.defaultProps = {
  children: 'Button',
  onClick: undefined,
  to: undefined,
  type: 'button',
  variant: 'primary',
  disabled: false
}

export default Button
