import styled from 'styled-components'

export const StyledIcon = styled.div`
  box-sizing: content-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  user-select: none;
`

export const StyledPicture = styled.img`
  display: block;
  width: 100%;
  border-radius: ${props => (props.isRounded ? '50%' : 'none')};
`
