/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Import React so that you can use JSX in HeadComponents
const React = require('react')
require('./src/css/typography.css')
const { pageWrapper, rootWrapper } = require('./gatsby-wrappers')

export const wrapRootElement = rootWrapper

export const wrapPageElement = pageWrapper
