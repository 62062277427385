import styled from 'styled-components'
import { ThemeDefault } from '../../00_tokens/theme-default'

export const StyledSpacer = styled.div`
  display: ${props => (props.type !== 'horizontal' ? 'inline-block' : 'block')};
  border: ${props =>
    props.isVisible && `2px dashed ${ThemeDefault.color.orangeYellow}`};
  width: ${props =>
    props.type !== 'horizontal' ? ThemeDefault.spacing[props.size] : '100%'};
  height: ${props => ThemeDefault.spacing[props.size]};
`
