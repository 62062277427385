import { oneOf, string } from 'prop-types'
import React from 'react'
import { mapSize, mapType } from './helpers'
import { StyledIcon, StyledPicture } from './icon.styles'

const Icon = ({ type, size }) => (
  <StyledIcon>
    <StyledPicture src={mapType(type)} width={mapSize(size)} />
  </StyledIcon>
)

Icon.propTypes = {
  /**
   * Icon type
   */
  type: string.isRequired,
  /**
   * Icon size
   */
  size: oneOf(['sm', 'md', 'lg', 'xl'])
}

Icon.defaultProps = {
  size: 'md'
}

export default Icon
