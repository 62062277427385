import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './src/components/00_tokens/global-styles'
import { ThemeDefault } from './src/components/00_tokens/theme-default'
import Layout from './src/components/04_templates/Layout'

export const rootWrapper = ({ element }) => (
  <ThemeProvider theme={ThemeDefault}>{element}</ThemeProvider>
)

export const pageWrapper = ({ element }) => (
  <>
    <GlobalStyles />
    <Layout>{element}</Layout>
  </>
)
