import styled from 'styled-components'

export const StyledMenuDesktop = styled.div`
  display: none;

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: none;
  }
`
