import { arrayOf, bool, shape, string } from 'prop-types'
import React, { useState } from 'react'
import BurguerMenu from '../../01_atoms/burger-menu/BurgerMenu'
import Image from '../../01_atoms/image'
import ButtonIcon from '../../02_molecules/button-icon/ButtonIcon'
import ContactMobile from '../../02_molecules/contact-mobile/ContactMobile'
import MenuDesktop from '../../02_molecules/menu-desktop'
import MenuMobile from '../../02_molecules/menu-mobile/MenuMobile'
import { StyledHeader, StyledHeaderContainer } from './header.styles'

export const PureHeader = ({
  logo,
  title,
  navLinks,
  buttons,
  contactLinks
}) => {
  const [navOpen, setNavOpen] = useState(false)
  const [contactOpen, setContactOpen] = useState(false)

  // FIXME: fix bug when close contact modal with button paper-plane
  // due to useOnClickOutside hook

  return (
    <>
      <StyledHeader>
        <StyledHeaderContainer className="container">
          <BurguerMenu navOpen={navOpen} setNavOpen={setNavOpen} />
          <Image to="/" imageData={logo.gatsbyImageData} alt={title} />
          <ButtonIcon
            id="paper-plane"
            icon="paperPlane"
            disabled={contactOpen ? true : false}
            onClick={() => setContactOpen(!contactOpen)}
          />
          <MenuDesktop navLinks={navLinks} buttons={buttons} />
        </StyledHeaderContainer>
      </StyledHeader>
      <MenuMobile
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        navLinks={navLinks}
      />
      <ContactMobile
        contactOpen={contactOpen}
        setContactOpen={setContactOpen}
        contactLinks={contactLinks}
      />
    </>
  )
}

PureHeader.propTypes = {
  logo: shape({
    gatsbyImageData: shape({
      src: string
    })
  }).isRequired,
  title: string,
  navLinks: arrayOf(
    shape({
      id: string,
      label: string,
      url: string,
      variant: string
    })
  ),
  buttons: arrayOf(
    shape({
      id: string,
      label: string,
      url: string,
      variant: string
    })
  ),
  contactLinks: arrayOf(
    shape({
      id: string,
      text: string,
      label: string,
      url: string,
      new_window: bool,
      variant: string,
      icon: shape({
        file: shape({
          url: string
        })
      })
    })
  )
}

PureHeader.defaultProps = {
  logo: {},
  title: 'Header',
  navLinks: [],
  buttons: []
}
