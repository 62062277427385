import { Link } from 'gatsby'
import { GatsbyStyledImage, StyledImageContainer } from './image.styles'
import { object, oneOf, string } from 'prop-types'
import React from 'react'

const Image = ({ imageData, to, href, alt, src, align }) => {
  if (href) {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {' '}
        <img alt={alt} src={src} />
      </a>
    )
  }

  if (src) {
    return <img alt={alt} src={src} />
  }

  if (to) {
    return (
      <Link to={to}>
        {' '}
        <GatsbyStyledImage loading="eager" alt={alt} image={imageData} />
      </Link>
    )
  }

  return (
    <StyledImageContainer align={align}>
      <GatsbyStyledImage loading="eager" alt={alt} image={imageData} />
    </StyledImageContainer>
  )
}

Image.propTypes = {
  /**
   * Source image
   */
  src: string,
  /**
   * gatsbyImageData from gql
   */
  imageData: object,
  /**
   * Image Link path
   */
  to: string,
  /**
   * Image external URL
   */
  href: string,
  /**
   * Alternative image name
   */
  alt: string.isRequired,
  /**
   * Image alignment
   */
  align: oneOf(['left', 'center', 'right'])
}

Image.defaultProps = {
  src: '',
  imageData: {},
  to: '',
  href: '',
  alt: '',
  width: '',
  maxwidth: '',
  minwidth: '',
  align: 'left'
}

export default Image
