import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { PureFooter } from './PureFooter'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query get_section_footer {
      allContentfulSectionFooter {
        edges {
          node {
            title
            logo {
              gatsbyImageData(width: 150)
            }
            email {
              label
              new_window
              url
              variant
              text
            }
            socialLinks {
              id
              label
              new_window
              url
              variant
              icon {
                file {
                  url
                }
              }
            }
            copyright {
              raw
            }
          }
        }
      }
    }
  `)

  // console.log('Footer -> data :::::', data)

  const {
    title,
    logo,
    email,
    socialLinks,
    copyright
  } = data.allContentfulSectionFooter.edges[0].node

  return (
    <PureFooter
      logo={logo}
      title={title}
      email={email}
      socialLinks={socialLinks}
      copyright={copyright}
    />
  )
}

export default Footer
