import styled, { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { StyledClients } from '../03_organisms/clients/clients.styles'
import { StyledFooter } from '../03_organisms/footer/footer.styles'
import { StyledTeam } from '../03_organisms/team/team.styles'
import { Link } from 'gatsby'

const containerWidthMobile = '90vw'
const containerWidthDesktop = '86vw'

export const GlobalStyles = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }

  #page-container {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;

    ${StyledFooter} {
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__index {
      background: linear-gradient(0deg, #FFFFFF 0%, #FDFDFF 62.1%, #F8F6FF 84.5%, #F0EBFF 100%);
    }
    &__details {
      height: 100%;
    }
  }

  .container {
    width: ${containerWidthMobile};

    @media (min-width: 992px) {
      width: ${containerWidthDesktop};
    }
  }

  section {
    position: relative;
    padding: 32px 0;

    > * {
      z-index: 2;
    }

    .svg--bottom {
      width: 100%;
      position: absolute;
      top: 99%;
      z-index: 1;

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
`

export const SectionContainer = styled.div`
  width: ${containerWidthMobile};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(32px + (40 - 32) * ((100vw - 320px) / (768 - 320)));

  @media (min-width: 768px) {
    gap: 40px;

    ${StyledTeam} & {
      margin: 0 15%;
    }
  }

  @media (min-width: 992px) {
    width: ${containerWidthDesktop};
    gap: 48px;
  }

  @media (min-width: 1200px) {
    gap: 56px;
  }

  ${StyledClients} & {
    align-items: stretch;
  }
`

export const GatsbyLink = styled(Link)`
  text-decoration: none;
`