import React from 'react'
import { bool, oneOf, string } from 'prop-types'
import { StyledSpacer } from './spacer.styles'

const Spacer = ({ type, size, isVisible }) => (
  <StyledSpacer type={type} size={size} isVisible={isVisible} />
)

Spacer.propTypes = {
  type: oneOf(['default', 'horizontal']),
  size: string,
  isVisible: bool
}

Spacer.defaultProps = {
  type: 'default',
  size: '0',
  isVisible: false
}

export default Spacer
