import styled from 'styled-components'

export const StyledServices = styled.section`
  ${props =>
    props.theming.background && `background: ${props.theming.background};`}
  padding-top: calc(64px + (112 - 64) * ((100vw - 320px) / (575 - 320)));
  display: flex;
  justify-content: center;

  .svg--bottom {
    top: calc(100% - 50px);
  }

  @media (min-width: 575px) {
    padding-top: calc(112px + (120 - 112) * ((100vw - 575px) / (768 - 575)));
  }

  @media (min-width: 768px) {
    padding-top: calc(120px + (160 - 120) * ((100vw - 768px) / (1200 - 768)));

    .svg--bottom {
      top: calc(
        100% + calc(-65px + (-115 - (-65)) * ((100vw - 768px) / (1200 - 768)))
      );
    }
  }
`

export const StyledServicesCards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  > div:nth-child(3n + 1) {
    --color-bg-1: #a0cfff;
    --color-bg-2: #99cfd5;
    --color-bg-3: #96d0c3;
    background: linear-gradient(
      0deg,
      var(--color-bg-1) 0%,
      var(--color-bg-2) 63.5%,
      var(--color-bg-3) 100%
    );

    &:hover {
      --color-box-1: #a0cfff;
      --color-box-2: #99cfd5;
      --color-box-3: #96d0c3;
      box-shadow: 0 0 6px var(--color-box-1), 0 0 8px var(--color-box-2),
        0 0 12px var(--color-box-3), 0 0 16px var(--color-box-3);
    }
  }

  > div:nth-child(3n + 2) {
    --color-bg-1: #a26dba;
    --color-bg-2: #bc7fa2;
    --color-bg-3: #eca176;
    --color-bg-4: #ffaf65;
    background: linear-gradient(
      0deg,
      var(--color-bg-1) 0%,
      var(--color-bg-2) 24.7%,
      var(--color-bg-3) 75%,
      var(--color-bg-4) 100%
    );

    &:hover {
      --color-box-1: #feae65;
      --color-box-2: #f0a471;
      --color-box-3: #c98895;
      --color-box-4: #a36db9;
      box-shadow: 0 0 6px var(--color-box-1), 0 0 8px var(--color-box-2),
        0 0 12px var(--color-box-3), 0 0 16px var(--color-box-4);
    }
  }

  > div:nth-child(3n + 3) {
    --color-bg-1: #a1b0ff;
    --color-bg-2: #a4b0fd;
    --color-bg-3: #ffbed1;
    background: linear-gradient(
      0deg,
      var(--color-bg-1) 0%,
      var(--color-bg-2) 3.7%,
      var(--color-bg-3) 100%
    );

    &:hover {
      --color-box-1: #a1b0ff;
      --color-box-2: #a4b0fd;
      --color-box-3: #ffbed1;
      box-shadow: 0 0 6px var(--color-box-1), 0 0 8px var(--color-box-2),
        0 0 12px var(--color-box-3), 0 0 16px var(--color-box-3);
    }
  }

  > div:nth-child(2n + 2) {
    flex-direction: row-reverse;

    > div:last-child {
      flex-direction: row-reverse;

      hr {
        margin-right: 0;
        margin-left: 16px;
      }

      p {
        text-align: right;
      }
    }
  }

  @media (min-width: 768px) {
    > div:nth-child(2n + 2) {
      flex-direction: row-reverse;

      > div:last-child {
        flex-direction: column;

        hr {
          margin: 0;
        }

        p {
          text-align: center;
        }
      }
    }
  }
`
