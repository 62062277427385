import styled from 'styled-components'

export const StyledTechnologies = styled.section`
  ${props =>
    props.theming.background && `background: ${props.theming.background};`}
  margin-top: calc(32px + (96 - 32) * ((100vw - 320px) / (768 - 320)));
  display: flex;
  justify-content: center;

  .svg--bottom {
    top: calc(
      100% + calc(-8px + (-80 - (-8)) * ((100vw - 320px) / (768 - 320)))
    );
    z-index: 0;
  }

  @media (min-width: 768px) {
    margin-top: calc(96px + (112 - 96) * ((100vw - 168px) / (1200 - 168)));

    .svg--bottom {
      top: calc(100% - 80px);
    }
  }

  @media (min-width: 992px) {
    .svg--bottom {
      top: calc(
        100% + calc(-80px + (-112 - (-80)) * ((100vw - 992px) / (1200 - 992)))
      );
    }
  }
`

export const TechnologiesCategories = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    gap: unset;
  }
`

export const TechnologiesCategory = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (min-width: 768px) {
    min-width: 50%;
    flex: 2 0 50%;
  }
`

export const TechnologiesList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem 3rem;
  flex-wrap: wrap;
`
