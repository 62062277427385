import React from 'react'
import Footer from '../03_organisms/footer'
import PropTypes from 'prop-types'
import Header from '../03_organisms/header'

const Layout = ({ children }) => {
  return (
    <div id="page-container">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
