import styled from 'styled-components'
import { ThemeDefault } from '../../00_tokens/theme-default'

const logoWidth = '175px'

export const StyledFooter = styled.footer`
  background-color: ${() => ThemeDefault.color.black};
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  #footer__logo {
    width: ${logoWidth};

    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    padding: 3rem 0;
  }
`

export const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:first-child) {
    margin-top: 2rem;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: ${logoWidth} 1fr ${logoWidth};

    > *:not(:first-child) {
      margin-top: 0;
    }
  }
`

export const StyledFooterLinks = styled.div`
  justify-self: center;

  > * {
    color: transparent;
  }

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`
