const UNIT_SIZE = 'rem'

const getSizeNumber = (value, regExp) =>
  Number(value.replace(new RegExp(regExp), ''))

/**
 * Map all the property values between 375px and 1200px */
export const getFluidSize = (theme, property, desktopSize, mobileSize) => {
  const mSize = theme[property][mobileSize]
  const dSize = theme[property][desktopSize]
  const sizesDiff =
    getSizeNumber(dSize, UNIT_SIZE) - getSizeNumber(mSize, UNIT_SIZE)
  // console.log(
  //   `calc(${mSize} + ${sizesDiff} * ((100vw - 23.4375rem) / 51.5625))`
  // )
  return `calc(${mSize} + ${sizesDiff} * ((100vw - 23.4375rem) / 51.5625))`
}
